export const darkMode = {
  bg: "#1c1c1c",
  textColor: "#e6e6e6",
  hrLine: "#373737",
  hover: "#292929",
  wrapperBg: "#000",
  softText: "#aaaaaa",
  closeColor: "#f8f8ff",
};

export const lightMode = {
  bg: "#fff",
  textColor: "#0d0d0d",
  hrLine: "#c6c6c6",
  hover: "#e0e0e0",
  hoverColorl: "#e6e6e6",
  wrapperBg: "#f2f2f2",
  softText: "#606060",
  closeColor: "#333333",
};
